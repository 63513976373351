import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Link, navigate, withAssetPrefix } from 'gatsby';
import React, { Component } from 'react';
import { compose } from 'recompose';

import Layout from '@/components/layout';
import SEO from '@/components/seo';

const noImage = '/assets/images/noImage.jpg';

type Props = {
  classes: {
    root: string;
    breadCrumb: string;
    breadCrumbSplit: string;
    itemBox: string;
    img: string;
    texts: string;
    date: string;
    title: string;
    excerpt: string;
    allReadLink: string;
  };
  pageContext: {
    newsList: {
      edges: WPPost[];
    };
  };
};

type State = {
  checked: boolean;
};

type WPPost = {
  node: {
    id: string;
    title: string;
    content: string;
    date: string;
    status: string;
    excerpt: string;
    featured_media: {
      source_url: string | null;
    };
  };
};

class NewsList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      checked: false,
    };
  }

  _formatDate(wordpressDate: string) {
    const dateObj = new Date(wordpressDate);
    return (
      dateObj.getFullYear() +
      '.' +
      (dateObj.getMonth() + 1) +
      '.' +
      dateObj.getDate()
    );
  }

  __handleOnClick = (id: string) => (
    _event: React.MouseEvent<HTMLDivElement>
  ) => {
    navigate(`/news/${id}`);
  };

  render() {
    const { classes } = this.props;
    const newsList = this.props.pageContext.newsList.edges;

    return (
      <Layout>
        <SEO title="NEWS一覧" />
        <div className={classes.root}>
          <div className={classes.breadCrumb}>
            <Link to={'/'}>TOP</Link>
            <span className={classes.breadCrumbSplit}> {'>'} </span>NEWS一覧
          </div>
          <div>
            {newsList.map(({ node }: WPPost) => {
              return (
                <div
                  className={classes.itemBox}
                  onClick={this.__handleOnClick(node.id)}
                >
                  <img
                    className={classes.img}
                    src={
                      node.featured_media && node.featured_media.source_url
                        ? node.featured_media.source_url
                        : withAssetPrefix(noImage)
                    }
                    alt={node.title}
                  />
                  <div className={classes.texts}>
                    <div className={classes.date}>
                      {this._formatDate(node.date)}
                    </div>
                    <div className={classes.title}>{node.title}</div>
                    <div
                      className={classes.excerpt}
                      dangerouslySetInnerHTML={{ __html: node.excerpt }}
                    ></div>
                    <div className={classes.allReadLink}>
                      <Link to={`/news/${node.id}`}>全て読む</Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: '40px 80px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px 10px',
      },
    },
    breadCrumb: {
      fontFamily:
        '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
      fontSize: 18,
      marginBottom: 40,
      marginLeft: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        marginBottom: 30,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
        marginBottom: 10,
      },
    },
    breadCrumbSplit: {
      fontFamily: 'Advent Pro',
      fontWeight: 'bold',
      marginLeft: 10,
      marginRight: 10,
      color: '#999',
    },
    itemBox: {
      backgroundColor: 'white',
      borderRadius: '7px',
      margin: '20px 20px',
      padding: '20px 20px',
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        padding: '10px 10px',
      },
    },
    img: {
      width: '200px',
      height: '200px',
      objectFit: 'cover',
      marginBottom: 0,
      [theme.breakpoints.down('sm')]: {
        width: '150px',
        height: '150px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100px',
        height: '100px',
      },
    },
    texts: {
      marginLeft: '20px',
      marginRight: '40px',
      textDecoration: 'none',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '10px',
        marginRight: '10px',
      },
    },
    date: {
      fontFamily: 'Advent Pro',
      fontStyle: 'normal',
      fontStretch: 'normal',
      fontSize: 30,
      letterSpacing: 1.1,
      marginBottom: '20px',
      [theme.breakpoints.down('sm')]: {
        fontSize: 23,
        marginBottom: '10px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
        marginBottom: '5px',
      },
    },
    title: {
      fontFamily:
        '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
      fontSize: 20,
      background: 'linear-gradient(transparent 50%, #ffff6b 0%)',
      display: 'inline',
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
      },
    },
    excerpt: {
      fontFamily:
        '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
      fontSize: 15,
      marginTop: '20px',
      marginBottom: '0px',
      display: 'show',
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
        marginTop: '10px',
        display: 'show',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
        marginTop: '5px',
        display: 'none',
      },
    },
    allReadLink: {
      textAlign: 'right',
      position: 'relative',
      fontFamily:
        '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
      fontSize: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 13,
        position: 'absolute',
        right: '40px',
      },
    },
  });

export default compose(withStyles(styles))(NewsList);
